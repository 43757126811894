import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"white shadow1 rounded d-flex",staticStyle:{"min-height":"80dvh","flex-direction":"column"},attrs:{"fluid":""}},[_c(VRow,{staticClass:"m-2",attrs:{"dense":""}},[_c(VCol,{staticClass:"pb-0",attrs:{"cols":"12"}},[_c(VToolbar,{staticClass:"mb-6",staticStyle:{"border-bottom":"1px solid #ccc !important","width":"100%","align-items":"center","justify-content":"space-between"},attrs:{"dense":"","color":"white","elevation":0}},[_c(VToolbarTitle,{staticClass:"subtitle-3"},[_vm._v("Check Stock")]),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c(VForm,{staticClass:"custom-form"},[_c(VTextField,{staticStyle:{"max-width":"300px"},attrs:{"outlined":"","dense":"","hide-details":"","placeholder":"Search Product","append-icon":"mdi-magnify"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)],1),_c(VDataTable,{staticClass:"custom-data-table shadow1 rounded overflow-hidden",attrs:{"dense":"","headers":_vm.productHeaders,"items":_vm.filteredProducts,"loading":_vm.$store.getters['product/loadingProducts'],"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.truncateDescription(item.description))+" ")]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c(VImg,{attrs:{"width":50,"height":50,"cover":"","src":("" + (_vm.$store.state.hostName) + (item.image))}})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }